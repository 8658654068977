body {
   margin: 0;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   font-family: "Noto Sans", sans-serif;
}

.pdBkgnd {
   background-image: linear-gradient(
      135deg,
      #333 6.25%,
      #222 6.25%,
      #222 50%,
      #222 50%,
      #333 56.25%,
      #222 56.25%,
      #222 100%
   ) !important;
   background-size: 11.31px 11.31px !important;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   min-height: 100vh;
   min-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
   font-family: "Noto Sans", sans-serif !important;
   font-weight: 700;
}

* {
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: -moz-none;
   -o-user-select: none;
   user-select: none;
}

.noScroll {
   overflow: hidden;
}

input {
   -webkit-user-select: text;
   -khtml-user-select: text;
   -moz-user-select: text;
   -o-user-select: text;
   user-select: text;
}

#json-pretty {
   position: absolute;
   bottom: 0;
   left: 0;
   opacity: 0.5;
   width: 100%;
   height: 400px;
   overflow: scroll;
}

img {
   pointer-events: none;
}

.maskX {
   -webkit-mask-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 90%,
      rgba(0, 0, 0, 0) 100%
   );
   mask-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 90%,
      rgba(0, 0, 0, 0) 100%
   );
   mask-size: cover;
}

.maskY {
   -webkit-mask-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 5%,
      rgba(0, 0, 0, 1) 95%,
      rgba(0, 0, 0, 0) 100%
   );
   mask-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 5%,
      rgba(0, 0, 0, 1) 95%,
      rgba(0, 0, 0, 0) 100%
   );
   mask-size: cover;
}

.transparentBG {
   background-image: linear-gradient(
         45deg,
         #444 25%,
         transparent 25%,
         transparent 75%,
         #444 75%
      ),
      linear-gradient(45deg, #444 25%, #333 25%, #333 75%, #444 75%);
   background-size: 20px 20px;
   background-position: 0px 0px, 10px 10px;
}

.bidderName {
   font-weight: 500;
   text-transform: title-case;
   font-size: 150px;
}

.bidderNameL3 {
   font-weight: 500;
   text-transform: title-case;
}

.bidderNumber {
   font-size: 80px !important;
}

.bidderNumberL3 {
   font-size: 50px;
}

.thankYou {
   /* display: none; */
   position: absolute;
   top: 50px;
   left: 0px;
   text-transform: uppercase;
   font-family: Gill Sans Italic;
   font-weight: 700 !important;
   padding: 15px 250px 10px 100px;
   background: linear-gradient(
      90deg,
      rgba(97, 57, 49, 1) 0%,
      rgba(97, 57, 49, 1) 64%,
      rgba(97, 57, 49, 0.6825105042016807) 76%,
      rgba(97, 57, 49, 0) 100%
   );
}

.thankYouL3 {
   position: absolute;
   bottom: 0px;
   left: 0px;
   text-transform: uppercase;
   font-family: Gill Sans Italic;
   font-weight: 700 !important;
   padding: 52px 120px 52px 100px;
   background: linear-gradient(
      90deg,
      rgba(97, 57, 49, 0.9) 0%,
      rgba(97, 57, 49, 0.8) 64%,
      rgba(97, 57, 49, 0.6825105042016807) 76%,
      rgba(97, 57, 49, 0) 100%
   );
}

.thankYouBig {
   font-weight: 600;
   font-size: 200px !important;
}

.thankYouBigL3 {
   font-weight: 600;
}

.rndComponent .handles {
   top: 0;
   left: 0;
   position: absolute;
   width: 100%;
   height: 100%;
   z-index: 1000;
   opacity: 0;
}

.rndComponent:hover .handles {
   opacity: 1;
}

.handles svg {
   width: 20px;
   height: 20px;
   position: absolute;
   transform-origin: center center;
}

.top svg {
   top: 0;
   left: 50%;
   transform: translateX(-50%) rotate(-90deg);
}

.right svg {
   top: 50%;
   right: 0;
   transform: translateY(-50%) rotate(0);
}

.bottom svg {
   bottom: 0;
   left: 50%;
   transform: translateX(-50%) rotate(90deg);
}

.left svg {
   position: relative;
   top: 50%;
   transform: translateY(-50%) rotate(-180deg);
}

.topLeft svg {
   top: 5px;
   left: 5px;
   transform: rotate(-135deg);
}

.topRight svg {
   top: 5px;
   right: 5px;
   transform: rotate(-45deg);
}

.bottomRight svg {
   top: -5px;
   right: 5px;
   transform: rotate(45deg);
}

.bottomLeft svg {
   top: -5px;
   left: 5px;
   transform: rotate(135deg);
}

.sidebarEditor div {
   font-size: 12px;
}

h4 {
   font-family: "Noto Sans", sans-serif !important;
   font-weight: 700 !important;
}
